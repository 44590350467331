/* 页面整体样式 */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fff; /* 设置背景为白色 */
    font-family: 'Roboto', Arial, sans-serif;
    margin: 0; /* 确保页面无外边距 */
    padding: 0; /* 确保页面无内边距 */
    box-sizing: border-box; /* 包括内边距和边框 */
}

/* 登录框样式 */
.login-box {
    width: 100%;
    max-width: 400px; /* 设置固定最大宽度 */
    padding: 40px 30px;
    margin-bottom: 0px; /* 增加间距 */
    background: #fff;
    border-radius: 20px; /* 圆弧边角 */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); /* 均匀阴影效果 */
    text-align: center;
    box-sizing: border-box; /* 确保 padding 不影响宽度 */
}

.login-form {
    width: 100%;
}

/* 输入框组样式 */
.login-input {
    width: 100%;
    padding: 15px 20px; /* 输入框内边距 */
    margin-bottom: 30px; /* 增加间距 */
    border: 1px solid #dadce0;
    border-radius: 30px; /* 输入框圆角 */
    font-size: 16px;
    outline: none;
    transition: border-color 0.2s, box-shadow 0.2s;
    box-sizing: border-box; /* 确保宽度计算准确 */
}

.login-input:focus {
    border-color: #1a73e8;
    box-shadow: 0 0 3px rgba(26, 115, 232, 0.5);
}

/* 按钮样式 */
.login-button {
    width: 100%;
    padding: 15px; /* 按钮内边距 */
    margin-bottom: 0px; /* 增加间距 */
    background: #4285F4; /* 按钮背景色 */
    border: none;
    border-radius: 30px; /* 按钮圆角 */
    color: #fff;
    font-size: 18px; /* 按钮文字大小 */
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s, box-shadow 0.3s;
}

.login-button:hover {
    background: #6fa8f9; /* 更浅的蓝色悬停效果 */
    box-shadow: 0 4px 8px rgba(66, 133, 244, 0.4); /* 按钮悬停阴影 */
}

svg {
    display: block;
    width: 100%; /* 根据需要调整宽度 */
    height: auto; /* 自动调整高度 */
    overflow: hidden; /* 裁剪超出的部分 */
}