/* 设置外层容器为全屏并居中 */
.page-container {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    /* height: 100vh;  */
    margin: 0 0 60px 0;
}

/* 原有样式 */
.recommendations-container {
    display: flex;
    align-items: center; /* 垂直居中标题与列表 */
    gap: 10px; /* 标题与问题间距 */
}

.recommendations-container h4 {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin: 0;
}

.recommendations-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 10px; /* 每个问题之间的间距 */
}

.recommendations-container li {
    cursor: pointer;
    background-color: #f5f5f5; /* 温和的背景色 */
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 14px;
    color: #F27A5C;
    transition: background-color 0.3s, color 0.3s;
}

.recommendations-container li:hover {
    background-color: #e6f7ff;
    color: #0056b3;
}